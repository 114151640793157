import React, { useState } from "react";
import { AppBar, Button, Popover, Toolbar, Typography } from "@mui/material";
import logo from "../Images/ConnorsPlantsLogoTransparentBackground.png";

const NavBar = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<AppBar position="static">
			<Toolbar>
				<img
					src={logo}
					className="App-logo"
					alt="logo"
					style={{ height: "75px" }}
				/>

				<Button color="inherit" href="/">
					Home
				</Button>
				<Button color="inherit" onClick={handleClick}>
					Contact Us
				</Button>
			</Toolbar>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<Typography style={{ padding: "10px" }}>Call or Text!</Typography>
				<Typography style={{ padding: "10px" }}>Phone: 651-239-4444</Typography>
				<Typography style={{ padding: "10px" }}>By Appointment</Typography>
			</Popover>
		</AppBar>
	);
};

export default NavBar;
