//Create a static footer element that will have a link to the about us page
//as well as our hours, a small map of our location, our phone and our email
//The footer should be on every page
//The footer should be responsive but static to the bottom of the content

import React from "react";
import { Box, Grid, Typography, Divider, useTheme } from "@mui/material";

import logo from "../Images/ConnorsPlantsLogoTransparentBackground.png";

export default function Footer() {
	const theme = useTheme();
	return (
		<Box
			bgcolor="primary.main"
			color={theme.palette.primary.textWhite}
			p={2}
			boxShadow={3}
			height={"auto"}
		>
			<Grid
				container
				display={"flex"}
				flexDirection={"row"}
				justifyContent={"center"}
				alignItems={"center"}
			>
				<Grid
					item
					xs={12}
					md={4}
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
					>
						<Typography textAlign={"center"} variant="h4">
							Hours
						</Typography>
						<Divider width={"25%"} color={theme.palette.primary.textWhite} />
						<Typography
							textAlign={"center"}
							variant="h5"
							sx={{ fontSize: "medium" }}
							paddingTop={2}
						>
							Monday - Friday: 8am - 6pm & By Appointment
						</Typography>
						<Typography
							textAlign={"center"}
							variant="h5"
							sx={{ fontSize: "medium" }}
						>
							Saturday: 9am - 12pm & By Appointment
						</Typography>
						<Typography
							textAlign={"center"}
							variant="h5"
							sx={{ fontSize: "medium" }}
						>
							Sunday: By Appointment Only
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
					>
						<img
							width={"200px"}
							height={"200px"}
							src={logo}
							loading="eager"
							title="Connors Plants"
							alt="Connors Plants"
						/>
					</Box>
				</Grid>

				<Grid item xs={12} md={4}>
					<Box
						p={2}
						width={"100%"}
						overflow={"wrap"}
						sx={{ overflowWrap: "anywhere" }}
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
					>
						<Typography textAlign={"center"} variant="h4">
							Contact Us
						</Typography>
						<Divider width={"40%"} color={theme.palette.primary.textWhite} />
						<Typography
							textAlign={"center"}
							variant="h5"
							sx={{ fontSize: "medium" }}
							paddingTop={2}
						>
							Phone: 651-239-4444
						</Typography>

						<Typography
							textAlign={"center"}
							variant="h5"
							sx={{ fontSize: "medium" }}
						>
							All orders must be placed by text for delivery only. We're a
							licensed landscape wholesaler and we work with nurseries that are
							not open to the public. No plants are stored on our location for
							sale.
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
