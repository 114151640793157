import React from "react";
import ReactDOM from "react-dom/client";

import Home from "./Components/Home";
import PlantDetail from "./Components/PlantDetail";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import ReactGA from "react-ga4";
import { CssBaseline } from "@mui/material";

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
ReactGA.initialize(trackingId);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<ThemeProvider theme={theme}>
		<CssBaseline />
		<Router>
			<NavBar />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/home" element={<Home />} />
				<Route path="/plant/:id" element={<PlantDetail />} />
			</Routes>
			<Footer />
		</Router>
	</ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
