import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#4caf50", // This is a green color
		},
		secondary: {
			main: "#ff9100", // This is an orange color
		},
	},
});

export default theme;
