import React from "react";
import { Box, Typography } from "@mui/material";

import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

function Home() {
	const location = useLocation();

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: location.pathname,
			title: "Home Page",
		});
	}, [location]);

	return (
		<Box padding={2}>
			<Helmet>
				<title>Connor's Plants</title>
				<meta name="description" content="Connor's Plants" />
				<meta name="keywords" content="plants, gardening, fruit trees" />
			</Helmet>

			<Typography
				variant="h1"
				align="center"
				//make smaller font
				style={{ fontSize: "4em", paddingTop: "20px" }}
				gutterBottom
			>
				Welcome to Connor's Plants
			</Typography>
			<Typography variant="h3" align="center" gutterBottom>
				We are a family owned and operated nursery located in the heart of Inver
				Grove Heights, MN.
			</Typography>
			<Typography variant="h3" align="center" gutterBottom>
				We specialize in fruit trees, shrubs, and perennials. More info to come
				November 2024!
			</Typography>
		</Box>
	);
}

export default Home;
