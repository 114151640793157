const plants = [
	{
		id: 1,
		name: "Blueberry",
		type: "Fruit Bush",
		sun: "Direct Sunlight",
		varieties: {
			Blueray: {
				description:
					"Blueray is a highbush blueberry that is known for its large, sweet berries. It is a very productive plant that is easy to grow and maintain. The Blueray blueberry bush is a great choice for gardeners in most climates",
				stock: 28,
			},
			Chippewa: {
				description:
					"Chippewa is a half-high blueberry that is a cross between a highbush and a lowbush blueberry. It is a very cold-hardy plant that produces large, sweet berries. The Chippewa blueberry bush is a great choice for gardeners in colder climates.",
				stock: 3,
			},
			Patriot: {
				description:
					"Patriot is a highbush blueberry that is known for its large, sweet berries. It is a very productive plant that is easy to grow and maintain. The Patriot blueberry bush is a great choice for gardeners in most climates.",
				stock: 18,
			},
			Superior: {
				desciption:
					"Superior is a highbush blueberry that is known for its large, sweet berries. It is a very productive plant that is easy to grow and maintain. The Superior blueberry bush is a great choice for gardeners in most climates.",
				stock: 18,
			},
		},
		price: 22,
		image:
			"https://cdn.shopify.com/s/files/1/0059/8835/2052/files/Brightwell_Blueberry_1_9dc5ee7d-21ea-4dc8-9edb-03bb36f0c4c8.jpg",
	},
	{
		id: 2,
		name: "Raspberry",
		type: "Fruit Shrub",
		sun: "Direct Sunlight",
		varieties: {
			"Himbro Top": {
				description:
					"Himbo Top is a summer-bearing red raspberry that is known for its large, sweet berries. It is a very productive plant that is easy to grow and maintain. The Himbo Top raspberry bush is a great choice for gardeners in most climates.",
				stock: 0,
			},
			"Joan J": {
				description:
					"Joan J is a summer-bearing red raspberry that is known for its large, sweet berries. It is a very productive plant that is easy to grow and maintain. The Joan J raspberry bush is a great choice for gardeners in most climates.",
				stock: 0,
			},
		},
		price: 17,
		image:
			"https://cdn.shopify.com/s/files/1/0059/8835/2052/files/Heritage_Raspberry_1_FGT_af269c43-b789-4585-a33c-6a9e5fb52548.jpg",
	},
	{
		id: 3,
		name: "Plum",
		type: "Fruit Tree",
		sun: "Direct Sunlight",
		//"Superior, Tonka",
		varieties: {
			Superior: {
				description: "",
				stock: 5,
			},
			Toka: {
				description: "",
				stock: 5,
			},
		},
		price: 140,
		image:
			"https://www.gertenswholesale.com/inet/storefront/getimage.php?recid=21831",
	},
	{
		id: 4,
		name: "Pear",
		type: "Fruit Tree",
		sun: "Direct Sunlight",
		//"Summercrisp, Parker",
		varieties: {
			Summercrisp: {
				description: "",
				stock: 5,
			},
			Parker: {
				description: "",
				stock: 5,
			},
		},
		price: 140,
		image:
			"https://cdn4.volusion.store/uyqbk-sezkn/v/vspfiles/photos/FRUPEAR-HOOD-2.jpg?v-cache=1686030121",
	},
	{
		id: 5,
		name: "Apple",
		type: "Fruit Tree",
		sun: "Direct Sunlight",
		//"Honeycrisp, McIntosh",
		varieties: {
			Honeycrisp: {
				description: "",
				stock: 19,
			},
			McIntosh: {
				description: "",
				stock: 5,
			},
		},

		price: 125,
		image:
			"https://cdn.shopify.com/s/files/1/0059/8835/2052/files/Honeycrisp_Apple_4_FGT_0b010b3b-df69-4775-b7d6-5460bbd055aa.jpg",
	},
	{
		id: 6,
		name: "Cherry",
		type: "Fruit Tree",
		sun: "Direct Sunlight",
		//"Bali, Montmorency",
		varieties: {
			Montmorency: {
				description: "",
				stock: 5,
			},
		},
		price: 140,
		image:
			"https://cdn.shopify.com/s/files/1/0059/8835/2052/files/Montmorency_Cherry_Tree_1_FGT_7febb660-4b04-4198-acf7-cf177124aed9.jpg",
	},
	//Add Techny, Emerald Green and american pillar arborvitae
	{
		id: 7,
		name: "Arborvitae",
		type: "Tree",
		sun: "Direct Sunlight",
		//"Techny, Emerald Green, American Pillar",
		varieties: {
			Techny: {
				description: "",
				stock: 15,
			},
			"Emerald Green": {
				description: "",
				stock: 3,
			},
			"American Pillar": {
				description: "",
				stock: 25,
			},
			"Green Giant": {
				description: "2' Green Giant Arborvitae",
				stock: 25,
			},
			Pyramidalis: {
				description: "1' Pyramidalis Arborvitae",
				stock: 40,
			},
			"Degroot's Spire": {
				description: "",
				stock: 20,
			},
		},
		price: 133,
		image:
			"https://cdn.shopify.com/s/files/1/0059/8835/2052/products/Techny_Thuja_Tree.jpg",
	},
	{
		id: 8,
		name: "Other, By Request",
		type: "Shrubs, Trees, and More",
		sun: "Direct Sunlight",
		varieties:
			//"Annuals, Perennials, Grasses, Shrubs, Trees, Vines, Retaining Walls, Pavers, Mulch, Soil, Rock, Sand, Edging and More!",
			{
				Annuals: {
					description: "",
				},
				Perennials: {
					description: "",
				},
				Grasses: {
					description: "",
				},
				Shrubs: {
					description: "",
				},
				Trees: {
					description: "",
				},
				Vines: {
					description: "",
				},
				"Retaining Walls": {
					description: "",
				},
				Pavers: {
					description: "",
				},
				Mulch: {
					description: "",
				},
				Soil: {
					description: "",
				},
				Rock: {
					description: "",
				},
				Sand: {
					description: "",
				},
				Edging: {
					description: "",
				},
				"And More!": {
					description: "",
				},
			},
		price: 0,
		image:
			"https://cdn.shopify.com/s/files/1/0059/8835/2052/products/Baby_Blue_1.jpg",
	},
	{
		id: 9,
		name: "Hydrangea",
		type: "Shrub",
		sun: "Direct Sunlight",
		comingSoon: true,
		varieties: {
			"The Original": {
				description: "",
				stock: 30,
			},
		},
		price: 0,
		image:
			"https://cdn11.bigcommerce.com/s-jmzfi5zcr2/images/stencil/544w/products/173/1302/The_Original_Endless_Summer_Hydrangea_Shrub_with_Blue_Flowers__23001.1498935128-Cropped-compressor__88657.1622573932.jpg?c=2",
	},
	{
		id: 10,
		name: "Chrysanthemum",
		type: "Perennial Flower",
		sun: "Direct Sunlight",
		comingSoon: false,
		varieties: {
			"Cheryl Pink Imp": {
				description: "",
				stock: 35,
			},
			"Tracy Orange": {
				description: "",
				stock: 35,
			},
			"Shannon White": {
				description: "",
				stock: 35,
			},
			"Chelsey Coral": {
				description: "",
				stock: 35,
			},
			"Arlette Purple": {
				description: "",
				stock: 35,
			},
			"Elena Gold": {
				description: "",
				stock: 35,
			},
			"Pamela Orange": {
				description: "",
				stock: 35,
			},
			"Chelsey White": {
				description: "",
				stock: 35,
			},
			"Summer Sunset": {
				description: "",
				stock: 35,
			},
		},
		price: 10,
		image:
			"https://www.siteone.com/medias/sys_master/PimProductImages/tmp/image-thumbnails/ProductAssets/US/NoBrand/itemImage/image-thumb__266176__pdpIcon/az-mum-garden-other-tracy-orange-991129/az-mum-garden-other-tracy-orange-991129.jpg",
	},
	{
		id: 11,
		name: "Spruce",
		type: "Evergreen Tree",
		sun: "Direct Sunlight",
		comingSoon: false,
		varieties: {
			Norway: {
				description: "3' - 4' Norway Spruce",
				stock: 36,
			},
			"Black Hills": {
				description: "2' - 3' Black Hills Spruce",
				stock: 18,
			},
		},
		price: 35,
		image:
			"https://northwoodstreefarmllc.com/wp-content/uploads/2020/09/norway-spruce-web7marketing.jpg",
	},
];

export default plants;
