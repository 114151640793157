import React, { useEffect } from "react";
import plants from "../plants";
import { useParams } from "react-router-dom";
import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const PlantDetail = () => {
	const { id } = useParams();
	const location = useLocation();
	const plant = plants.find((plant) => plant.id === parseInt(id));

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			//plant/:id is the page path
			page: location.pathname,
			//include the plant name in the title
			title: "Plant Detail Page",
		});
	}, [location]);

	if (!plant) {
		return <div>Plant not found</div>;
	}

	return (
		<Box width={"100vw"}>
			<Helmet>
				<title>{plant.name}</title>
				<meta name="description" content={plant.name} />
				<meta name="keywords" content={plant.name} />
			</Helmet>

			<Box style={{ padding: "10px" }}>
				<Typography variant="h3" component="h2">
					{plant.name}
				</Typography>
				<Typography variant="h4" component="h3">
					{plant.type}
				</Typography>

				<Typography variant="h6" color="textSecondary" component="p">
					${plant.price}
				</Typography>

				<Grid container spacing={2}>
					<Grid item sx={6}>
						<img src={plant.image} alt={plant.name} style={{ width: "50%" }} />
					</Grid>

					<Grid
						display="flex"
						flexDirection="row"
						justifyContent="flex-start"
						alignItems="left"
						container
						item
						xs={12}
					>
						{!plant.comingSoon ? (
							Object.entries(plant.varieties).map(([variety, details]) => (
								<Grid
									item
									xs={6}
									md={3}
									key={variety}
									style={{ paddingRight: "20px" }}
								>
									<Typography variant="body1" component="p">
										{variety}
									</Typography>
									{details.stock >= 5 && (
										<Typography
											variant="body2"
											component="p"
											style={{
												color: "green",
											}}
										>
											In Stock
										</Typography>
									)}
									{details.stock >= 1 && details.stock <= 4 && (
										<Typography
											variant="body2"
											component="p"
											style={{
												color: "orange",
											}}
										>
											Low Availability
										</Typography>
									)}
									{details.stock === 0 && (
										<Typography
											variant="body2"
											component="p"
											style={{
												color: "red",
											}}
										>
											Out of Stock
										</Typography>
									)}

									{details.stock < 0 ||
										(details.stock === undefined && (
											<Typography
												variant="body2"
												component="p"
												style={{
													color: "orange",
												}}
											>
												Call for Inventory
											</Typography>
										))}
								</Grid>
							))
						) : (
							<Box width={"100%"}>
								<Alert severity="info">Coming Soon</Alert>
								<Grid container spacing={2}>
									{Object.entries(plant.varieties).map(([variety, details]) => (
										<Grid
											item
											xs={6}
											md={3}
											key={variety}
											style={{ paddingRight: "20px" }}
										>
											<Typography variant="body1" component="p">
												{variety}
											</Typography>
											<Typography
												variant="body2"
												component="p"
												color={"orange"}
											>
												Coming Soon
											</Typography>
										</Grid>
									))}
								</Grid>
							</Box>
						)}
					</Grid>
				</Grid>
			</Box>
			<Box style={{ padding: "10px" }}>
				<Button variant="contained" color="primary" href="/home">
					Back to Home
				</Button>
			</Box>
		</Box>
	);
};

export default PlantDetail;
